import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

function Faq () {

  const { t } = useTranslation()
  
  const [ isOpenAcc, openAcc ] = useState(0)

  const faqs = [
    {
      title: t('faqTitle1'),
      description: <iframe className='aspect-video w-full' src="https://drive.google.com/file/d/11G5IyMCsfHYRkG0mY5N1JkO8yGb8LV3M/preview"></iframe>
    },
    {
      title: t('faqTitle2'),
      description: <iframe className='aspect-video w-full' src="https://drive.google.com/file/d/1FZKc6G0G-fHvvlJeKdVmfYc7hWV9k0Uk/preview"></iframe>
    },
    {
      title: t('faqTitle3'),
      description: <iframe className='aspect-video w-full' src="https://drive.google.com/file/d/1xuQducKYFbrWzKlJHRsZnipCGyFUU-x-/preview"></iframe>
    },
    {
      title: t('faqTitle4'),
      description: <iframe className='aspect-video w-full' src="https://drive.google.com/file/d/1jUroybK8tzL1uQsSo_-injHWAuIOXs-f/preview"></iframe>
    }
  ]

  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/1c0610da06e3edba525e31bbf4bf526fa276d28803c039a64992d024874448b71b02826c5169acbf1c7c61b0dd764488a8a0bf8cd98872c2474650c847a0ffe234f1c22d82f685685cc5bfa38d5bd67b3cfa88080b46a965d68d693de5546d5439ef6d2d5800e97258a00974dc45dfebf67e847305ed821e943f/file-1736775970398.jpg" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/60 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('FAQBannerText')}</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='app-main-container'>
          {
            faqs && faqs.map((item, i) => {
              return (
                <div>
                  <div onClick={() => openAcc(i)} className='text-app-blue font-bold text-xl sm:text-2xl py-2 cursor-pointer select-none'>
                    {item && item.title}
                  </div>
                  {
                    isOpenAcc == i && (
                      <div className='text-sm md:text-base py-2'>
                        {item && item.description}
                      </div>
                    )
                  }
                </div>
              )
            })
          }
        </div>
      </section>
    </div>
  )

}

export default Faq