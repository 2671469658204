module.exports = {
  PORT: "4014",
  NODE_ENV: "production",

  SERVER_DEV_URL: "http://localhost:3123",
  SERVER_STAGING_URL: "https://golfngroove-api.cloudxier.dev",
  SERVER_PROD_URL: "https://golfngroove-api.cloudxier.com",

  CLIENT_DEV_HOST: "localhost",
  CLIENT_STAGING_HOST: "golf-event.netlify.app",
  CLIENT_PROD_HOST: "golfngroove.bigeventasia.id",

  CLIENT_DEV_URL: "http://localhost:3000",
  CLIENT_STAGING_URL: "https://golf-event.netlify.app",
  CLIENT_PROD_URL: "https://golfngroove.bigeventasia.id",

  MAIN_WEBSITE_TITLE: 'GNG | ',
  SITE_NAME: 'Golf N Groove'
}
