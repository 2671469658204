import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Newsletter from 'components/layout/Newsletter';
import OurSponsors from './ticketing/pages/OurSponsors';
import useFetch from 'utils/hooks/useFetch';
import { GET_ALL_PUBLIC_POSTS } from 'api';

function Home() {
  const { data: publicPosts, fetch: getAllPublicPosts } = useFetch(GET_ALL_PUBLIC_POSTS)
  const { t } = useTranslation();

  useEffect(() => {
    getAllPublicPosts();
  }, [getAllPublicPosts])

  return (
    <div>
      <section className='hidden sm:block'>
        <div className='relative'>
          <img src="https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/1c0610da06e3edba525e31bbf4bf526fa276d28803c039a64992d024874448b71b02826c5169acbf1c7c61b0dd764488a8a0bf8cd98872c2474650c847a0ffe234f1c22d82f685685cc5bfa38d5bd67b3cfa88080b46a965d68d693de5546d5439ef6d2d5800e97258a00974dc45dfebf67e847305ed821e943f/file-1736775970398.jpg" alt="main" className='max-h-[90vh] w-full object-cover object-center' />
          {/* <div className='absolute top-0 bottom-0 left-0 right-0 text-white'>
            <div className='app-main-container flex flex-col justify-center items-start h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl md:text-4xl max-w-md font-semibold sm:font-bold app-animation-translate-start'>{t('mainBannerText')}</p>
              <a href="/about">
                <button className='text-sm sm:text-base flex items-center gap-2 app-animation-translate-start'>
                  <p>{t('learnMore')}</p>
                  <ArrowRightIcon className='h-3 sm:h-4 w-3 sm:w-4 fill-white' />
                </button>
              </a>
            </div>
          </div> */}
        </div>
      </section>

      <section className='grid grid-cols-1 sm:grid-cols-2'>
        <img src="https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/18c918644b2ead6ece48509703de372de45ecc71e8aa1b76beb7599325dc8b525853f39a95698ff928a663d50154ab41fbfa3f0e654135d4d9b32335ee81da72725be74f3728f7e92e5acd5e092fba08c9dc8b39ecaf42798bb94417c12e408d5b214d99e544757428ef0bea3abc31fefe421356206e42332fe7/rez-file-1736347445718.JPG" alt="main" className='hidden sm:block w-full h-full aspect-video object-cover object-center app-animation-translate-right-start' />
        <img src="https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/365c211c0095fedfa4ef133009a7ea648721f56980672585c81b0f93305ca7d2233402ae47760f7d8b9b7e5ae3923a56a66a471f03d3430901f6ee38459185fe29415d52c933e68aaf3b0da2059265fa372020f9156e1cff5ee4095929bfd2e2714a8beecf4f13f630e81642beb3b036882c1564c09773fc539a/file-1736758676546.jpg" alt="main" className='sm:hidden w-full h-full object-cover object-center app-animation-translate-right-start' />
        <div className='bg-app-blue-2 text-white px-4 py-6 md:py-12 md:px-10 lg:px-20 flex items-center justify-center sm:justify-start'>
          <div >
            <p className='text-xl sm:text-2xl md:text-3xl font-semibold sm:font-bold max-w-lg app-animation-translate-start'>{t('firstHighlightText')}</p>
            <a href='/prices'>
              <button className='text-lg sm:text-xl bg-emerald-800 font-bold text-white rounded-md px-12 py-2 text-sm sm:text-base disabled:opacity-50 mt-5'>
                {t('registerNowButton')}
              </button>
            </a>
          </div>
        </div>
      </section>

      {/* <section className='bg-app-grey'>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('eSportConsultTitle2')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{logo.generalBrands}</div>
        </div>
      </section> */}

      <OurSponsors landingPage={true} />

      {/* <section className='bg-app-grey'>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('ourRecentEvents')}
          </p>
          <div className='grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-8 py-4'>

            {publicPosts?.filter(post => post?.tag?.toLowerCase() === "event").slice(0, 6).map((item, i) => (
              <div key={i} className='space-y-4'>
                <img className="w-full aspect-[4/3] object-cover border" src={item.img_cover} alt={item.title} />
                <p className='font-bold text-sm sm:text-lg'>{item.title}</p>
                <p className='font-semibold text-xs sm:text-base text-app-blue-3 truncate'>{item.summary}</p>
                <a href={`article/${item?.id}`} className='flex items-center gap-2 text-xs sm:text-sm '>
                  <p>{t('learnMore')}</p>
                  <ArrowRightIcon className='h-2 w-2 sm:h-3 sm:w-3 fill-black' />
                </a>
              </div>
            ))}


          </div>
        </div>
      </section> */}

      {/* <Newsletter /> */}
    </div>
  )
}

export default Home