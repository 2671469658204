import React from 'react'
import { useTranslation } from 'react-i18next';
import Newsletter from 'components/layout/Newsletter'
import ImageGallery from 'react-image-gallery'

function About() {
  const { t } = useTranslation();

  const about = {
    description: (
      <>
        <img src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/18c918644b2ead6ece48509703de372de45ecc71e8aa1b76beb7599325dc8b525853f39a95698ff928a663d50154ab41fbfa3f0e654135d4d9b32335ee81da72725be74f3728f7e92e5acd5e092fba08c9dc8b39ecaf42798bb94417c12e408d5b214d99e544757428ef0bea3abc31fefe421356206e42332fe7/rez-file-1736347445718.JPG'/>
        <br />
        <p className='app-animation-translate-start text-justify'>
          {t('aboutUsParagraph1')}
        </p>
        <br />
        <iframe className='aspect-video w-full' src="https://drive.google.com/file/d/1Ys7iauIajeBsrjL63aW343k93hs1sFXy/preview"></iframe>
        <br />
        <p className='app-animation-translate-start text-justify'>
          {t('aboutUsParagraph2')}
        </p>
        <br />
        <img src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/c21fdcc0baa98935986de7d4b40c35ca6663e81f7cd3bd8c0934e35c1c8df902b17897f67eef8b5fa4669ad70d14974f8da617d8623aa39570ea1229776f05440a774485fbe6bd514d52defe17a8aeae25c2fc1623dea45619d9c6181dbffedcf1e64df4b911bfb214ab44479f05e4f86237d2f3b597f8313ac4/rez-file-1736347476147.JPG' />
        <br />
        <img src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/a5abb9e88cab66b5140f9df74e5659af5014d6da855dafcbdb25b4f369008c4d2a236e7ae6819b14206eecd45df61ae2264c6b8eca2fb2b6795482d398927b989498e9101c2e6702489691af22b57656197b470aa3524d575e44c24582d4d853b36cbead7aae7abef52a7d9e2c64de07eef267e2f42b817921ab/rez-file-1736347512048.JPG' />
        <br />
        <img src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/725df3cb6d598bc097cb3af50c56fb36c846d96e82f0a0a92bfb5942554496733e1efd03fdd3f16522adbfc7eb3cecdd7a1ab79b49fbb91ad1e5ff43dfd7ca61d390074ee81d56b3e403d0cc23a862f661785dc75e1ea1c6829d408463dfdbec87c51856247420326e4f4fb5ff979dafdf5f93111e3f83bb933a/rez-file-1736347539104.JPG' />
        <br />
        <img src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/9c4871151501880924c5e807ee16ed7e2414d00646aee0c669b673f8d72c5e904a938aba0b54aff2013316d4162f3fcb79d84250b04a1a0d2981eaa81108edf0762d1231c0a97be5455a5c2c6c9b399b5c178152be4f6d13559594d235568c407a4ee61319efcd930bb175f4645fa7c269ca0442270b2fb558a8/rez-file-1736347558472.JPG' />
        <br />
        <img src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/37f97a5b57f4394643e4be484a17bab3c88a7d71a66a095a70f2ed72212d7c45057626ef6207922caebb94787f59d0f907f50016682cb4ca154fb0b848911e1237adaf5259c9f9ecbc8a92e3fb344e656447b5621286359c8d2d37931c89d287a13053fed352cece5453be4ee82025c19c4a2931f4742167f068/rez-file-1736347583286.JPG' />
        <br />
        <img src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/55a0ac418479b40d9718dd589c93e000151f8e59ff8d0b70e9dab255f10234975cf7479017ae73be2f6e22b3cff797754a9263f222ed40a3dd344212c5a06c412e1a3be2379a7283f53e569d200bb2799d567c8c7ba0ac230d2997dedfbf0c7bbf54ce37e4a22e031a21e6cbda217e5e3d43bf7d5125303cf3b3/rez-file-1736347609736.JPG' />
      </>
    ),
    our_role_img: [{
      original: 'https://storage.googleapis.com/nizerteklintasbumi/banner/Setup-Ecosystem_NLB.png',
      thumbnail: 'https://storage.googleapis.com/nizerteklintasbumi/banner/Setup-Ecosystem_NLB.png'
    }]
  }


  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/1c0610da06e3edba525e31bbf4bf526fa276d28803c039a64992d024874448b71b02826c5169acbf1c7c61b0dd764488a8a0bf8cd98872c2474650c847a0ffe234f1c22d82f685685cc5bfa38d5bd67b3cfa88080b46a965d68d693de5546d5439ef6d2d5800e97258a00974dc45dfebf67e847305ed821e943f/file-1736775970398.jpg" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/60 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('aboutUsBannerText')}</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('aboutUs')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{about.description}</div>
        </div>
      </section>

      {/* <section className='bg-app-grey'>
        <div className='app-main-container grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 items-center'>
          <img className='app-animation-translate-right-start' src={visionMission} alt="vision and mission" />
          <div className='space-y-4 app-animation-translate-start'>
            <div>
              <p className='text-sm sm:text-base md:text-lg uppercase font-bold'>Vision</p>
              <p className='text-sm sm:text-base md:text-lg font-medium'>Lorem ipsum dolor sit amet lorem ipsum dolor sit amet</p>
            </div>
            <div>
              <p className='text-sm sm:text-base md:text-lg uppercase font-bold'>Mission</p>
              <p className='text-sm sm:text-base md:text-lg font-medium'>Lorem ipsum dolor sit amet lorem ipsum dolor sit amet</p>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className='bg-app-grey'>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('aboutUsTitle2')}
          </p>
          <div>
            <ImageGallery items={about.our_role_img} showThumbnails={false} showPlayButton={false} />
          </div>
        </div>
      </section> */}

      {/* <Newsletter /> */}
    </div>
  )
}

export default About