import React from 'react'

import WhatsappIcon from 'assets/icons/WhatsappIcon';

function WhatsappButton() {

    return (
        <div className='chat-widget fixed z-50 bottom-0 right-0 flex flex-col gap-4 items-end justify-end mr-4 mb-4 md:mr-8 md:mb-8 drop-shadow-md'>
            <button
                className='bg-app-blue-light h-10 w-10 md:h-14 md:w-14 flex items-center justify-center rounded-full drop-shadow-md transition-all md:hover:-translate-y-1'
                onClick={() => window.open(`https://wa.me/+6285211168510`, "_blank")}
            >
                <WhatsappIcon className="h-5 w-5 md:h-7 md:w-7 fill-black" />
            </button>
        </div>
    )
}

export default WhatsappButton;